/* eslint-disable max-len */
import React from 'react';

import { GetServerSidePropsResult } from 'next';

import GeneralistHome from '@/components/home/_verticals/generalist/GeneralistHome';
import { GeneralistHomeStaticProps } from '@/components/home/_verticals/types/generalist';
import { InitVerticalHomeContextProps } from '@/components/home/_verticals/types/home';
import { cacheLifetime } from '@/config';
import VerticalHomeContextWrapper from '@/contexts/VerticalHome/VerticalHomeContextWrapper';
import getHomePageStaticProps from '@/data/pages/home/serverState';

const GeneralistHomePage = (props: GeneralistHomeStaticProps): React.ReactNode => {
  const verticalHome: InitVerticalHomeContextProps = {
    vertical: 'generalist',
    heroData: [],
    shopData: { shops: [] },
  };

  return (
    <VerticalHomeContextWrapper verticalHome={verticalHome}>
      <GeneralistHome {...props} />
    </VerticalHomeContextWrapper>
  );
};

export async function getServerSideProps({ res }: { res: any }): Promise<GetServerSidePropsResult<GeneralistHomeStaticProps>> {
  // See https://nextjs.org/docs/going-to-production#caching
  res.setHeader(
    'Cache-Control',
    cacheLifetime,
  );
  // Get the server side static data in each request

  const props = (await getHomePageStaticProps() as unknown) as GeneralistHomeStaticProps;

  return {
    props,
  };
}

export default GeneralistHomePage;
