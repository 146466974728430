import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { SwiperSlide } from 'swiper/react';

import CardsSwiper from '@/components/common/CardsSwiper';
import ShopCard from '@/components/home/Shops/ShopCard';
import { baseUrl } from '@/config';
import getAdsForShops from '@/data/client/adsForShops';

import 'swiper/css';
import 'swiper/css/navigation';

/* istanbul ignore next */
const PromotionsInfoModal = dynamic(
  () => import('@/components/landing/Modals/PromotionInfoModal'),
  { ssr: false },
);

const JfgShops = ({ shops }) => {
  const [groupedAds, setgroupedAds] = useState({});
  const [adsForShops, setAdsForShops] = useState([]);

  useEffect(() => {
    if (shops?.length) {
      getAdsForShops(shops)
        .then((ads) => {
          setAdsForShops(ads);
        });
    }
  }, [shops]);

  useEffect(() => {
    const filteredAds = [];
    if (adsForShops?.length > 0) {
      adsForShops.forEach((ad) => {
        filteredAds.push(
          {
            list_id: ad.list_id,
            shop_list_id: ad.shop_list_id,
            price: ad.price.value,
            parameters: ad.parameters,
            friendly_url: ad.url,
            image_url: (ad.images?.length > 0 && ad.images[0].image_size_variations[0]?.url),
            title: ad.subject,
          },
        );
      });
    }

    const result = filteredAds.reduce((r, a) => {
      const x = r;
      x[a.shop_list_id] = x[a.shop_list_id] || [];
      x[a.shop_list_id].push(a);
      return x;
    }, Object.create(null));
    setgroupedAds(result);
  }, [adsForShops]);

  if (!shops?.length || !adsForShops?.length) {
    return null;
  }

  return (
    <CardsSwiper
      title="Boltok"
      showAllUrl={`${baseUrl}/boltok`}
      infoModalComponent={PromotionsInfoModal}
    >
      {shops?.map((shop) => (
        <SwiperSlide key={shop.id}>
          <ShopCard shop={shop} ads={groupedAds[shop.id]} />
        </SwiperSlide>
      ))}
    </CardsSwiper>
  );
};

export default JfgShops;
